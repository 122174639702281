import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
export const frontmatter = {
  title: "Lifestyle Binge: Netflix's Engagement Effect On Branding & Creativity",
  date: "2018-09-12",
  author: "Roberto Ramos",
  authorTitle: "Senior Vice President, Strategy and Communications of The Doneger Group",
  teaserImage: "../../../static/media/2018-09-12-Lifestyle-Binge-0.jpg",
  path: "2018-09-12-Lifestyle-Binge",
  teaserText: "We are living in the golden age of culture and television, and we have venture capitalism, creative entrepreneurship and Netflix's sexy-smart algorithms largely to thank."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="Lifestyle Binge: Netflix's Engagement Effect On Branding & Creativity" description="We are living in the golden age of culture and television, and we have venture capitalism, creative entrepreneurship and Netflix's sexy-smart algorithms largely to thank." author='Roberto Ramos' keywords={['Robots']} />
      <ImageLandscape imageSrc="2018-09-12-Lifestyle-Binge-0.jpg" caption="Netflix" />
      <MDXTag name="p" components={components}>{`Netflix has made the journey into storytelling more addictive than ever, and the morning commute much more inspiring and enjoyable. Binge watching is a real thing (guilty as charged), serving as the perfect antidote to our stressed out, trigger-ready anxiety fueled by the noise of today. Netflix has become a proxy for the collective longing for escapism, and by doing so it will change how current and future generations consume and shape culture. Lifestyle and fashion brands are already feeling the effects, and by replicating it are enjoying more cultural depth.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We are living in the golden age of culture and television, and we have venture capitalism, creative entrepreneurship and Netflix's sexy-smart algorithms largely to thank. And of course, a talented creative class that has come beautifully unhinged at the collapse of the old entertainment complex.`}</MDXTag>
      <MDXTag name="p" components={components}>{`This golden age of content is making us more culturally savvy and open to deeper, more engaging forms of storytelling and dialogue. This is cool and very necessary, especially as a response to the swift, spontaneous and mostly brief nature of social media and its preference for quick formats such as memes. Content binging is also a powerful response to the world of Reality TV, at a time when reality is not too rosy for many. The Netflix effect goes in the other direction and delves into our need for discovery, with layers of depth unavailable in other spaces.`}</MDXTag>
      <MDXTag name="p" components={components}>{`What Netflix has done by making a wide array of fiction available to many around the world is to rewire us to think more creatively and passionately. HBO, the precursor to Netflix, should be given a lot of credit in this space with shows like The Sopranos and The Wire, taking character building and plot development to another level. But HBO missed the personalization formula that runs with the algorithm-induced spark around knowing what you're into and then serving you streaming content for life.`}</MDXTag>
      <ImageLandscape imageSrc="2018-09-12-Lifestyle-Binge-1.jpg" caption="Stranger Things Maze, Universal Studios" />
      <MDXTag name="p" components={components}>{`The most important effect stemming from the "mainstreaming" of culture on demand, à la Netflix, Hulu and Amazon, will be fueling the next base of creative talent, including our future rock star designers, editors and hyphenated lifestyle entrepreneurs. These young kids now binging on indie comedies and cult-level Scandinavian thrillers will have their future aesthetic and storytelling chops greatly amplified by the Netflix experience. Brands are also taking copious notes.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Lifestyle and fashion houses and brands are thinking more like lifestyle entertainment companies and the better brands are expressing their unique vision of the world and their craftsmanship in multiple video formats, including the more evocative and flexible longer formats of video. Think of what Louis Vuitton has done with theatrical, video-friendly runway shows or culturally immersive experiences such as its "Volez, Voguez, Voyagez," exhibit. Gucci's Alessandro Michele has turned the brand into a mini art-house film publisher, with offbeat pieces with characters full of colorful charm and idiosyncrasies.`}</MDXTag>
      <ImageLandscape imageSrc="2018-09-12-Lifestyle-Binge-2.jpg" caption="Gucci Pre-Fall 2018" />
      <MDXTag name="p" components={components}>{`What modern, character-driven entertainment today is showing us is the importance of nurturing a unique personality. The following are some lessons Netflix can teach us about how to become a culture disruptor.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Think like an entertainment company.`}</MDXTag>{` Dial up your understanding of the cultural conversation around you and identify stories and experiences where your brand can make a cultural statement. Go beyond your comfort zone to identify new collaborators, writers, producers and directors whose sensitivities and stories marry well with your vision and vibe.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Go bold with creativity.`}</MDXTag>{` Take chances in terms of your creative statements. Deliver around the powerful edges of your brand. Think of elements that will spark conversation and will set you aside from the rest. Today is an awful day to be in the middle, so find and amplify the uniqueness of your story. Tap into your purpose and vision for creative content. Think of what Patagonia did, for instance, to take on the POTUS and his administration's weakening of environmental policies.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Give campaigns more depth.`}</MDXTag>{` A greater consumer appreciation for creative depth and cadence bodes well for impactful campaigns. Explore creative ways of taking your story and message in different ways to different media. Find ways of dialing up interactivity, including ways to make the consumer more involved. Think of how Nike encourages its followers to unlock campaigns through specific actions. Finally, explore all the great, creative grit content around the behind-the-scenes. Tap into the popularity of documentaries to give the creatively hungry generation a preview of your brand's creative ethos.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Deliver personalization.`}</MDXTag>{` Netflix has perfected the art of mass curation. Think of ways in which you can deliver personalization at scale. Explore more targeted content, niche product offerings, and whenever possible, a high-touch and emotive customer service and sales approach.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Foster a people-first culture.`}</MDXTag>{` Netflix broke new ground with its "Freedom & Responsibility" HR manifesto, giving more power to employees. Find ways to encourage your employees to take more creative risks, be more entrepreneurial, and ultimately, be stronger, authentically cool brand ambassadors. For all its reputation around creativity, the fashion industry must do a better job at creating a people-centric approach that allows creativity to thrive; that will be the foundation for creating a truly magical retail experience.`}</MDXTag>
      <MDXTag name="p" components={components}>{`There is something primordially evocative about stories. It takes us back to when we were little, and the world of make believe and reality blended around possibility. It made us feel very big. We've lost that over the years and are now craving it. The clamoring for experiences is a needed opportunity for fashion and retail, spaces traditionally seen as shallow, to make a full connection. So go ahead and set creative securities aside and start exploring ways to make your brand a story to remember.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    